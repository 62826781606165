<template>
  <b-row>
    <b-col md="9">
      <div>
        <v-select
          id="subType"
          v-model="currentSubType"
          label="title"
          :options="currentSubTypeOption"
          placeholder=""
          name="subType"
          :disabled="readOnly"
          class="font-small-4 mb-1 services-select"
        />
      </div>
    </b-col>
    <b-col class="d-flex align-items-center">
      <div
        v-if="!readOnly"
        class="cursor-pointer"
        @click="$emit('deleteSubType', index)"
      >
        <img
          src="../../../assets/images/logo/basket.png"
          alt="basket"
        >
      </div>
    </b-col>
    <hr>
  </b-row>
</template>

<script>
import {BCol, BRow } from "bootstrap-vue";
import vSelect from "vue-select";
import {mapGetters} from "vuex";

export default {

  components: {
    BRow,
    BCol,
    vSelect,
  },

  props: {
    index: {
      type: Number,
      default: 0,
    },
    readOnly: Boolean,
  },

  data() {
    return {
      currentSubType: "",
      currentSubTypeOption: [],
      subTypeOption: [
        {
          title: "GRM",
          value: "GRM",
        },
        {
          title: "GRS",
          value: "GRS",
        },
        {
          title: "PMAIC",
          value: "PMAIC",
        },
        {
          title: "PMAMC",
          value: "PMAMC",
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
        getSubTypes: "clients/getSubTypes",
    }),
  },

  watch: {
    getSubTypes(val) {
      this.fillRequestedSubTypes(val);
    },
    currentSubType(val) {
      if (val && this.getSubTypes.indexOf(val.value) === -1)
          this.$emit("updateSubType", val, this.index);
    },
  },

  async created() {
    try {
      this.fillRequestedSubTypes(this.getSubTypes);
    } catch (err) {
      this.error = err
    }
  },

  methods: {
    fillRequestedSubTypes() {
      const items = [];
      const subTypes = this.getSubTypes.split(',');
      subTypes.map((item) => {
        items.push({title: item, value: item})
      })
      this.currentSubType = items[this.index]
      this.currentSubTypeOption = this.subTypeOption.filter(i => this.getSubTypes.indexOf(i.title) === -1)
    }
  },
}
</script>

<style scoped>

</style>